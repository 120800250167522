import React, { useState, useEffect } from 'react'; 
import DataCard from './DataCard'; 
import AreaGraph from './Graph';
import {toast, Toaster} from 'react-hot-toast';
import ApiData from '../ApiData';
import ApiDataClub from '../ApiDataClub';
import ToClipboard from './CopyCliboardButton';
import Loading from './Loading';
const Dashboard = ({ start, end, restaurant_id, loading,setLoading }) => {
   const [metrics, setMetrics] = useState({

   }); 
   const [loadingButton,setLoadingButton] = useState(true);

   const [metricsPainelBordo,setMetricsPainel] = useState({

   });
   const [xAxisData, setXAxisData] = useState([]); 
   const [yAxisDataComandas, setYAxisDataComandas] = useState([]);
   const [yAxisDataNotasEmitidas,setYAxisDataNotasEmitidas] = useState([]);

   const fetchRestaurantsGraphData = async (restaurant_id, signal) => {
    
    try {
      setXAxisData({});
      setYAxisDataComandas({});
      setYAxisDataNotasEmitidas({});
      const result = await ApiData.post({restaurant_id: restaurant_id},'graphic/restaurant/comandas',signal);
  
      
      if (result.data && result.data.Comandas && result.data["Notas Emitidas"]) {
        console.log(result.data);
        const xAxisData = result.data.Comandas.map(entry => 
          new Date(entry.week_start).toLocaleDateString()
        );
  
        const yAxisDataComandas = result.data.Comandas.map(entry => entry.count);
        const yAxisDataNotasEmitidas = result.data["Notas Emitidas"].map(entry => entry.count);
  
        setXAxisData(xAxisData);
        setYAxisDataComandas(yAxisDataComandas);
        setYAxisDataNotasEmitidas(yAxisDataNotasEmitidas);
      }
      else {
        console.error("Formato inesperado dos dados:", result);
      }
    }catch (error) {
      if (error.name === 'AbortError') {
        console.log('Requisição Cancelada');
        setLoading(true);
      } else {
        console.error('Erro ao buscar dados do gráfico', error);
      }
    }
  };
  
  
  const fetchGeneralGraphData = async (signal) => {
    
    try {
      
      setXAxisData({});
      setYAxisDataComandas({});
      setYAxisDataNotasEmitidas({});
  
      const result =  await ApiData.post({},'graphic/dashboard/comandas',signal);
  
      // Verifica a estrutura dos dados e extrai as duas séries
      if (result.data && result.data.Comandas && result.data["Notas Emitidas"]) {
        // Processa o eixo X com base nas datas semanais
        const xAxisData = result.data.Comandas.map(entry => 
          new Date(entry.week_start).toLocaleDateString()
        );
  
        // Processa as séries de dados para o eixo Y
        const yAxisDataComandas = result.data.Comandas.map(entry => entry.count);
        const yAxisDataNotasEmitidas = result.data["Notas Emitidas"].map(entry => entry.count);
  
        // Atualiza os estados do gráfico 
        setXAxisData(xAxisData);
        setYAxisDataComandas(yAxisDataComandas);
        setYAxisDataNotasEmitidas(yAxisDataNotasEmitidas);
      } else {
        console.error("Formato inesperado dos dados:", result);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Requisição Cancelada');
        setLoading(true);
      } else {
        console.error('Erro ao buscar dados do gráfico', error);
      }
    }
  };
  
  const refactorDataToCopy = (metrics) => {
   const painel = [
      "$GMV TOTAL",
      "COMANDAS CRIADAS",
      "$GMV QRCODE",
      "$GMV TOTEM",
      "$GMV DELIVERY TAKEAT",
      "$GMV IFOOD",
      "$GMV PRESENCIAL",
      "$VENDAS GERADAS CASHBACK",
      "$VENDAS GERADAS CAMPANHAS CRM",
      "RESTAURANTES COM CASHBACK RESGATADO",
      "RESTAURANTES CAMPANHAS CRM",
      "RESTAURANTES COM POS",
      "RESTAURANTES COM TOTEM"


    ]


    const orderedData = painel.reduce((acc, key) => {
      if (metrics[key] !== undefined) {
        acc[key] = metrics[key];
      }
      return acc;
    }, {});

    console.log(orderedData);
    setMetricsPainel(orderedData);

  }
  
   const fetchGeneralData = async (start, end,signal) => { 
    setLoading(true);
    setLoadingButton(true);
    setMetricsPainel({});
    const order = [
      "$GMV TOTAL",
      "COMANDAS CRIADAS",
      "$GMV QRCODE",
      "$GMV TOTEM",
      "$GMV SMART POS",
      "%CONVERSÃO DE CADASTRO NO CLUBE",
      "$GMV PRESENCIAL",
      "$GMV DELIVERY TAKEAT",
      "$GMV IFOOD",
      "$CASHBACK GERADO",
      "$CASHBACK RESGATADO",
      "$VENDAS GERADAS CASHBACK",
      "$VENDAS GERADAS CAMPANHAS CRM",
      "RESTAURANTES CAMPANHAS CRM",
      "RESTAURANTES COM POS",
      "RESTAURANTES COM CASHBACK RESGATADO",
      "RESTAURANTES COM TOTEM",
      "NOTAS EMITIDAS",
      "RESTAURANTES > 9 COMANDAS",
      "RESTAURANTES > 1 COMANDA"
    ];

    setMetrics({});
    
    try { 
      
          const response_takeat = await ApiData.post({start_date: start, end_date: end},'dashboard/metrics', signal); // metricas gerais do sistema

          const response_club = await ApiDataClub.post({start_date: start, end_date:end },'club/metrics', signal);

          
          const response = {
            ...response_club.data,
            ...response_takeat.data
        };
        

        const orderedData = order.reduce((acc, key) => {
          if (response[key] !== undefined) {
            acc[key] = response[key];
          }
          return acc;
        }, {});
        //console.log(response);
        setMetrics(orderedData); 
        refactorDataToCopy(response)
      }
        catch (error) { console.error('Error fetching general data:', error); 
           if(error.name === 'AbortError'){
        console.log('Requisição Cancelada');
        setLoading(true);}
        }finally{
      setLoading(false); 
      setLoadingButton(false);
    
    }
  };




    const fetchRestaurantData = async (start, end, restaurant_id,signal) => { 
          setLoading(true);
          setLoadingButton(true);
          setMetrics({});
          try { 
            
        
        const data = await ApiData.post({start_date: start ,end_date: end,restaurant_id: restaurant_id},'restaurant/metrics',signal);

        setMetrics(data.data); 
        } catch (error) { console.error('Error fetching restaurant data:', error);
          if(error.name === 'AbortError'){
            console.log('Requisição Cancelada');
            setLoading(true);}
         }finally{
         setLoading(false);
         setLoadingButton(false);
         }
         };
         
         
         
         
         useEffect(() => {
          let currentController = new AbortController(); // controlar requisições
          const signal = currentController.signal; // sinal de cancelamento de requisições (Caso uma requisição seja feita antes que a atual termine, cancela a atual e começa a nova)
           if (start && end)
            
             { if (restaurant_id) 

              { 

                fetchRestaurantData(start, end, restaurant_id,signal);
                fetchRestaurantsGraphData(restaurant_id,signal);

               } else { 
                toast('As métricas gerais podem demorar um pouco mais!', {
                  icon: '⚠️',
                });
                fetchGeneralData(start, end,signal); 
                fetchGeneralGraphData(signal);
                } 
              }
              return () => currentController.abort(); 
            }, [start, end, restaurant_id]);




  return (
<div>

<div >
      {loading && (<Loading/>)}
      </div>
    <div className='copy_button'>
    {Object.keys(metricsPainelBordo).length> 0 && !restaurant_id  &&(<ToClipboard data={metricsPainelBordo} text={'Copiar Painel de Bordo (Produto) '} color={"#111111"} disabled={loadingButton}/>)}

    {Object.keys(metrics).length> 0 && (<ToClipboard data={metrics} text={'Copiar'} disabled={loadingButton}/>)}

    </div>
    <div className="dashboard">

      {Object.keys(metrics).map((key) => (
          <DataCard key={key} title={key} value={metrics[key] !== null? metrics[key]:'Por favor aguarde...'} />
        ))}
      {yAxisDataComandas.length > 0 && yAxisDataNotasEmitidas.length>0 && Object.keys(metrics).length> 0 &&( 

        
       <AreaGraph 
      title="COMANDAS X NOTAS"
      xAxisLabel= "Semana"
      xAxisData={xAxisData} 
      yAxisLabel= ""
      yAxisDataComandas={yAxisDataComandas} 
      yAxisDataNotasEmitidas={yAxisDataNotasEmitidas}
    
        
    />
  
      
      )}
    </div>
    <div style={{ zIndex: 9999 }}>
  <Toaster position="top-center" />
</div>
</div>    
  );
};

export default Dashboard;

import { useAuth } from "./AuthContext";
const API_DATA = process.env.REACT_APP_BACKEND_DATA_ANALYSIS_CLUB
const ApiDataClub = {
    post: async(body = {    },endpoint,signal) => {
        let response;
    if(Object.keys(body).length > 0 ){
        response = await fetch(`${API_DATA}/${endpoint}`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
            body: JSON.stringify(body),
            signal,
            
        });
    }else{

        response = await fetch(`${API_DATA}/${endpoint}`,{
        method: 'POST',
        headers:{'Content-Type': 'application/json'},
        
        signal

        });


    }

    if(!response.ok){
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;

    }




};

export default ApiDataClub;

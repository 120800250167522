import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast,Toaster } from 'react-hot-toast';
import { useAuth } from '../AuthContext';
import { Button } from 'ui-kit-takeat';
import { DefaultInput } from 'ui-kit-takeat';
import bgImg from '../assets/bg_login.png';
import ApiLogin from '../ApiLogin';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
  const{login} = useAuth(); //AuthContext.js => Contexto / Autenticação

  const handleLogin = async (e) => {
    e.preventDefault();

    try { 

      const token = await ApiLogin.post({email: email, password:password});

        if (token) {
          login(token); // Chama a função de login para atualizar o contexto e o localStorage
          navigate('/panel');
        } else {
          toast.error('Erro: token de autenticação não encontrado');
        }
        

        login(token);

        navigate('/panel'); //Navega até o componente principal se o login for efetuado
      }catch(error){
        console.error("Erro ao buscar dados", error);
      }

  };

  return (
    
    <div className='login_background' style={{backgroundImage: `url(${bgImg})`}}> 
    

      
    <div className='login'>
      <h2>Login</h2>
      <form onSubmit={handleLogin} className='form_style' >
        <DefaultInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          
        />
        <DefaultInput
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          
        />
        <Button type='submit' title='Entrar' />
        <Toaster/>
      </form>
    </div>
  
    </div>
  );
};

export default Login;

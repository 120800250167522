import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';




const BrazilMap = ({title,data,dataLabel,minColor,maxColor, style}) => {
  useEffect(() => {
    (async () => {
      const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/br/br-all.topo.json'
      ).then(response => response.json());


      Highcharts.mapChart('map', {
        chart: {
          map: topology,
          backgroundColor: '#F0F1F7'
        },
        title: {
          text: title
        },
        subtitle: {
          text: 'Source map: <a href="https://code.highcharts.com/mapdata/countries/br/br-all.topo.json">Brazil</a>'
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          min: 0,
          minColor: minColor ,
          maxColor: maxColor
        },
        series: [{
          data: data,
          name: dataLabel,
          borderColor: '#000001',
          states: {
            hover: {
              color: '#FFFAFA'
            }
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}'
          }
        }]
      });
    })();
  }, [title,data,dataLabel]); // O array vazio [] faz o useEffect rodar apenas uma vez após a montagem do componente

  return (
    <div id="map" style={style}></div>
  );
};

export default BrazilMap;

import React from "react";
import {toast, Toaster} from 'react-hot-toast';
import { Button } from "takeat-design-system-ui-kit";
const ToClipboard = ({ data , text ,color, disabled}) => {
  const copyToClipboard = () => {
    const textToCopy = Object.values(data).join("\n"); // Extrai apenas os valores e une por linhas

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success('Dados Copiados!');
      })
      .catch((err) => {
        toast.error("Erro ao copiar para o clipboard");
      });
  };

  return (
    <div>
      <Button onClick={copyToClipboard} customColor={color} variant="outlined" disabled={disabled}>
      {text}
      </Button>
    </div>
  );
};

export default ToClipboard;

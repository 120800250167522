import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles.css';
import App from './App';
import { UiKitTheme } from 'takeat-design-system-ui-kit';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
 //<React.StrictMode>
 <UiKitTheme>
    <App />
</UiKitTheme>
// </React.StrictMode>
);

import React from "react";
import {toast, Toaster} from 'react-hot-toast';
import BrazilMap from "./BrazilMap";
import { useAuth } from '../AuthContext';
import { useEffect,useState } from "react";
import ApiData from "../ApiData";
import logoImg from '../assets/Union.png'
import {Button} from 'ui-kit-takeat';
import { useNavigate } from 'react-router-dom';
import { Select } from 'takeat-design-system-ui-kit';
import DataCard from "./DataCard";
import Loading from "./Loading";
const GeneralData = () => {
 
  const [restaurants,setRestaurants] = useState([]); 
  const [meanTotalMRR, setMeanTotalMRR] = useState();
  const [meanTotalSurvivalDays,setMeanTotalSurvivalDays] = useState();
  const [meanMRR,setMeanMRR] = useState();
  const [meanTempoDeUso,setMeanTempoDeUso] = useState();
  const [filters, setFilters] = useState({
    has_clube: false,
    has_pos: false,
    has_totem: false,
    is_deleted: false,
    is_not_deleted: false,
    faturamento: false, 
    faturamento_25K: false ,
    faturamento_50K: false,
    faturamento_100K: false,
    faturamento_150K: false,
    faturamento_200K: false,
    state: ''
  });
  

const [mapData,setMapData] = useState([]);


const navigate = useNavigate();
const{logout} = useAuth();
const handleLogout= () =>{
    logout();
    navigate('/login');

  };

const fetchMapData = async () => {
  try {
    const response_restaurants = await ApiData.post({}, "general/restaurants");
    const response_faturamento = await ApiData.post({}, "general/comandas");

    const restaurantsWithFaturamento = response_restaurants.data.restaurants.map((restaurant) => {


      const faturamento = response_faturamento.data.restaurants.find(
        (f) => f.restaurant_id === restaurant.id
      );
      

      return {
        ...restaurant,
        faturamento: faturamento ? faturamento.faturamento : 0 
      };
    });
    setMeanTotalMRR(response_restaurants.data.mean_mrr);
    setMeanTotalSurvivalDays(response_restaurants.data.mean__survival_days);
    setRestaurants(restaurantsWithFaturamento);
  } catch (error) {
    console.error('Erro ao buscar dados:', error);
  }
}

const handleFilterChange = (e) => {
  const { name, checked } = e.target;

  // Atualiza o filtro selecionado
  setFilters((prevFilters) => ({
    ...prevFilters,
    [name]: checked,
    ...(name.startsWith("faturamento") && { // Se for filtro de faturamento
      faturamento: name === "faturamento" ? checked : false,
      faturamento_25K: name === "faturamento_25K" ? checked : false,
      faturamento_50K: name === "faturamento_50K" ? checked : false,
      faturamento_100K: name === "faturamento_100K" ? checked : false,
      faturamento_150K: name === "faturamento_150K" ? checked : false,
      faturamento_200K: name === "faturamento_200K" ? checked : false,
    }),
  }));
};

const isAnySelected = Object.entries(filters)
.filter(([key]) => key.startsWith("faturamento")) // Considera apenas os filtros de faturamento
.some(([, value]) => value);
  useEffect(() => {
    fetchMapData();
  }, []);

const filteredRestaurants = restaurants.filter((restaurant) => {
  const { has_clube, has_pos, has_totem, is_deleted,is_not_deleted,  
    faturamento,  
    faturamento_25K,
    faturamento_50K,
    faturamento_100K,
    faturamento_150K,
    faturamento_200K } = filters;
  return (
    (!has_clube || restaurant.has_clube === 1) &&
    (!has_pos || restaurant.has_pos === 1) &&
    (!has_totem || restaurant.has_totem === 1)&&
    (!is_deleted || restaurant.is_deleted === 1)&&
    (!is_not_deleted || restaurant.is_deleted === 0) &&
    (!faturamento || restaurant.faturamento<=25000) &&
    (!faturamento_25K || (restaurant.faturamento>25000 && restaurant.faturamento<=50000) )&&
    (!faturamento_50K || (restaurant.faturamento>50000 && restaurant.faturamento<=100000) ) &&
    (!faturamento_100K || (restaurant.faturamento>100000 && restaurant.faturamento <=150000)) &&
    (!faturamento_150K || (restaurant.faturamento>150000 && restaurant.faturamento <= 200000)) &&
    (!faturamento_200K || restaurant.faturamento>200000)
  );
}); 

console.log(filteredRestaurants);

useEffect(() =>{
  const stateCount = filteredRestaurants.reduce((acc, restaurant) => {
    const state = restaurant.state;
    if(state){
    const stateCode = `br-${restaurant.state.toLowerCase()}`;
    acc[stateCode] = (acc[stateCode] || 0) + 1;
    }
    
    
    return acc;
  }, {});

  const totalsAndAverages = filteredRestaurants.reduce(
    (totals, restaurant) => {
      if (restaurant.mrr) {
        totals.totalMrr += restaurant.mrr;
        totals.countMrr++;
      }
      if(restaurant.survival_days){
        totals.totalSurvivalDays += restaurant.survival_days;
        totals.countSurvivalDays++;
      }
      return totals;
    },
    { totalMrr: 0, countMrr: 0, totalSurvivalDays: 0, countSurvivalDays: 0 }
  );

  // Calcula as médias
  const imeanMrr = totalsAndAverages.countMrr > 0 
    ? totalsAndAverages.totalMrr / totalsAndAverages.countMrr 
    : meanTotalMRR;

  const imeanSurvivalDays = totalsAndAverages.countSurvivalDays > 0 
    ? totalsAndAverages.totalSurvivalDays / totalsAndAverages.countSurvivalDays 
    : meanTotalSurvivalDays;

  console.log("Média MRR:", imeanMrr);
  console.log("Média Survival Days:", imeanSurvivalDays);
  setMeanMRR(imeanMrr);
  setMeanTempoDeUso(imeanSurvivalDays);
  const mapDataArray = Object.entries(stateCount);
  setMapData(mapDataArray);
}, [filters, restaurants]);



return(
  <div className="background_general_data">
        {Object.keys(restaurants).length === 0 &&<Loading/> }  
        <header className='header'>
         
         <img src={logoImg} alt='Logo' className='header-logo'/>
        
         
         <Button title= "Sair" onClick ={handleLogout} style={{position:'absolute',right:20}} inverted buttonColor='#FFFFFF'/> 
         
       </header>
  <div className="dashboard_general">  

  {Object.keys(restaurants).length> 0 &&(<div className="background">
  <div style={{display: 'flex', marginLeft: 300}} >
  <h1>Restaurantes</h1>
  </div>

    <div style={{display: 'flex', flexDirection: 'row', marginLeft: 100}}>
      <div className="general_selects" >
        <label>
          <input
            type="checkbox"
            name="has_clube"
            checked={filters.has_clube}
            onChange={handleFilterChange}
          />
          Tem Clube
        </label>

        <label>
          <input
            type="checkbox"
            name="has_pos"
            checked={filters.has_pos}
            onChange={handleFilterChange}
          />
          Tem POS
        </label>

        <label>
          <input
            type="checkbox"
            name="has_totem"
            checked={filters.has_totem}
            onChange={handleFilterChange}
          />
          Tem Totem
        </label>
        <label>
          <input
            type="checkbox"
            name="is_deleted"
            checked={filters.is_deleted}
            onChange={handleFilterChange}
          />
          Deletado
        </label>
        <label>
          <input
          type="checkbox"
          name="is_not_deleted"
          checked ={filters.is_not_deleted}
          onChange={handleFilterChange}
          />
        Não Deletado 
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento"
          checked ={filters.faturamento}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento}
          />
          VENDAS 0k - 25k
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento_25K"
          checked ={filters.faturamento_25K}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento_25K}
          />
        VENDAS 25K - 50k
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento_50K"
          checked ={filters.faturamento_50K}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento_50K}
          />
        VENDAS 50K - 100K 
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento_100K"
          checked ={filters.faturamento_100K}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento_100K}
          />
        VENDAS 100K-150k 
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento_150K"
          checked ={filters.faturamento_150K}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento_150K}
          />
          VENDAS 150K - 200K
        </label>
        <label>
          <input
          type="checkbox"
          name="faturamento_200K"
          checked ={filters.faturamento_200K}
          onChange={handleFilterChange}
          disabled={isAnySelected && !filters.faturamento_200K}
          />
        VENDAS 200K +
        </label>

      </div>
      <div style={{width: '1000px'}}>
      <BrazilMap 
        title={''} 
        data={mapData} 
        dataLabel={'Restaurantes'} 
        minColor={'#FFEFD5'} 
        maxColor={'#800000'} 
        style={{height: '1000px', width: '100%'}} 
      />
      </div>
    </div>
    </div >)}
   
    <div className="general_information">
  
     {(meanMRR || meanTotalMRR) && (meanTempoDeUso || meanTotalSurvivalDays)&& (
    <div className="general_data_cards">
     
      <div className="general_data_card">
        <DataCard
          title={'$MRR médio (Dos restaurantes que têm o MRR registrado)'}
          value={meanMRR}
        />
      </div>
    
   
      <div className="general_data_card">
      <DataCard 
          title={'Tempo médio de uso do sistema (em dias)'}
          value={`${meanTempoDeUso.toPrecision(4)}`}
        />
        
      </div>

  </div>
  )}
{meanMRR && meanTempoDeUso &&(<div>
  <h1>Restaurantes Filtrados</h1>
  <div className="filtered-restaurants-container">
    <ul>
      {filteredRestaurants.map((restaurant) => (
        <li key={restaurant.id}>
          {`Nome: ${restaurant.fantasy_name} -|- Estado: ${restaurant.state} -|- Faturamento: R$${restaurant.faturamento}`}
        </li>
      ))}
    </ul>
  </div>
</div>
)}
</div>
    </div>
    </div>
);
};
export default GeneralData;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoutes';
import MetricsForm from './components/MetricsForm';
import Login from './components/Login';
import NotFound from './NotFound';
import BrazilMap from './components/BrazilMap';
import GeneralData from './components/GeneralTakeatInformations';
function App() {
  const token = localStorage.getItem('authToken');
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={token?(<MetricsForm/>):(<Login/>)} />
          <Route path="/login" element={<Login/>}/>
          <Route 
            path="/panel" 
            element={
              <PrivateRoute>  
                <MetricsForm /> 
              </PrivateRoute>
            } 
          />
          <Route path="*" element={<NotFound />} />  {/*Not found para demais rotas não espeficicadas*/}
          <Route path="/general" element={
            <PrivateRoute>
            <GeneralData/>
            </PrivateRoute>}/>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
